import ApiClient from './ApiClient';

class SallaPanel extends ApiClient {
  constructor() {
    super('salla', { accountScoped: true });
  }

  getAllOrders(customerId) {
    return axios.get(`${this.url}/customers/${customerId}/orders`);
  }

  getProducts() {
    return axios.get(`${this.url}/products`);
  }

  getAllOrdersByPage({ customerId, page }) {
    return axios.get(`${this.url}/customers/${customerId}/orders?page=${page}`);
  }

  getCustomers({ query, page }) {
    return axios.get(`${this.url}/customers?page=${page}&keyword=${query}`);
  }

  getLinkedCustomer(customerId) {
    return axios.get(`${this.url}/customers/${customerId}`);
  }

  editCustomer(customerId, payload) {
    return axios.put(`${this.url}/customers/${customerId}`, {
      ...payload,
    });
  }

  getOrderDetails({ customerId, orderId }) {
    return axios.get(`${this.url}/customers/${customerId}/orders/${orderId}`);
  }

  getOrderStatuses(customerId) {
    return axios.get(`${this.url}/customers/${customerId}/orders/statuses`);
  }

  updateOrderStatus({ customerId, orderId, selectedStatus }) {
    return axios.post(
      `${this.url}/customers/${customerId}/orders/${orderId}/update_status`,
      {
        status_id: selectedStatus.id,
      }
    );
  }

  exportInvoice({ customerId, orderId }) {
    return axios.get(
      `${this.url}/customers/${customerId}/orders/${orderId}/export`
    );
  }
}

export default new SallaPanel();
