import * as types from '../mutation-types';
import SallaAPI from '../../api/salla';
import SallaPanelAPI from '../../api/sallaPanel';

export const state = {
  records: {
    events: [],
    currentEvent: {},
    inboxes: [],
    token: '',
    status: '',
    ordersWithPagination: {
      orders: [],
      meta: {},
    },
    products: {
      records: [],
      meta: {},
    },
    customersWithPagination: {
      customers: [],
      meta: {},
    },
    linkedCustomer: {},
    orderDetails: {},
    orderStatuses: [],
  },
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isFetchingOrdersByPage: false,
  },
};

export const getters = {
  getEvents($state) {
    return $state.records.events;
  },
  getInboxesIDs($state) {
    return $state.records.inboxes;
  },
  getEvent: $state => {
    return $state.records.currentEvent;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getStatus($state) {
    return $state.records.status;
  },
  getToken($state) {
    return $state.records.token;
  },
  // conversation integration panel
  getOrders($state) {
    return $state.records.ordersWithPagination;
  },
  getProducts($state) {
    return $state.records.products;
  },
  getCustomers($state) {
    return $state.records.customersWithPagination;
  },
  getLinkedCustomer($state) {
    return $state.records.linkedCustomer;
  },
  getOrderDetails($state) {
    return $state.records.orderDetails;
  },
  getOrderStatuses($state) {
    return $state.records.orderStatuses;
  },
};

export const actions = {
  getSallaInboxes: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getSallaInboxes();
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_INBOXES, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getAllEvents: async ({ commit }, inbox_id) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getAllEvents(inbox_id);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ALL_EVENTS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getEvent: async ({ commit }, params) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getEvent(params);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_EVENT, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  updateEvent: async ({ commit }, { id, ...eventParams }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await SallaAPI.update({ id, eventParams });
      commit(types.default.EDIT_EVENT, { id, enabled: eventParams.enabled });
      commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: false });
      throw error;
    }
  },
  sallaConnection: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.getSallaConfig();
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_CONFIGURATION, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  sallaCheck: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaAPI.checkSallaStatue();
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_STATUS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  // conversation integration panel
  getOrders: async ({ commit }, customerId) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaPanelAPI.getAllOrders(customerId);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_ORDERS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getProducts: async ({ commit }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaPanelAPI.getProducts();
      console.log(response.data);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_PRODUCTS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getOrdersByPage: async ({ commit }, payload) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetchingOrdersByPage: true });
    try {
      const response = await SallaPanelAPI.getAllOrdersByPage(payload);
      commit(types.default.SET_EVENT_UI_FLAG, {
        isFetchingOrdersByPage: false,
      });
      commit(types.default.SET_SALLA_ORDERS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, {
        isFetchingOrdersByPage: false,
      });
    }
  },
  getOrderDetails: async ({ commit }, payload) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetchingOrdersByPage: true });
    try {
      const response = await SallaPanelAPI.getOrderDetails(payload);
      commit(types.default.SET_EVENT_UI_FLAG, {
        isFetchingOrdersByPage: false,
      });
      commit(types.default.SET_SALLA_ORDER_DETAILS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, {
        isFetchingOrdersByPage: false,
      });
    }
  },
  getOrderStatuses: async ({ commit }, customerId) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await SallaPanelAPI.getOrderStatuses(customerId);
      commit(types.default.SET_EVENT_UI_FLAG, {
        isUpdating: false,
      });
      commit(types.default.SET_SALLA_ORDER_STATUSES, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, {
        isUpdating: false,
      });
    }
  },
  updateOrderStatus: async ({ commit }, payload) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetchingOrdersByPage: true });
    try {
      const response = await SallaPanelAPI.updateOrderStatus(payload);
      commit(types.default.SET_EVENT_UI_FLAG, {
        isFetchingOrdersByPage: false,
      });
      commit(types.default.SET_SALLA_UPDATE_ORDER_STATUSES, payload.id);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, {
        isFetchingOrdersByPage: false,
      });
    }
  },
  getCustomers: async ({ commit }, payload) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaPanelAPI.getCustomers(payload);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_CUSTOMERS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  clearCustomers: ({ commit }) => {
    commit(types.default.CLEAR_SALLA_CUSTOMERS);
  },
  getLinkedCustomer: async ({ commit }, customerId) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SallaPanelAPI.getLinkedCustomer(customerId);
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_SALLA_LINKED_CUSTOMERS, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  editCustomer: async ({ commit }, { customerId, ...payload }) => {
    commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await SallaPanelAPI.editCustomer(customerId, payload);
      commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: false });
      commit(types.default.EDIT_SALLA_CUSTOMER, response.data);
    } catch (error) {
      commit(types.default.SET_EVENT_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.default.SET_EVENT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_ALL_EVENTS]($state, data) {
    $state.records.events = data.events;
  },
  [types.default.SET_SALLA_INBOXES]($state, data) {
    $state.records.inboxes = data.inboxes;
  },
  [types.default.SET_SALLA_CONFIGURATION]($state, data) {
    $state.records.status = data.status;
    $state.records.token = data.connection_token;
  },
  [types.default.SET_SALLA_STATUS]($state, data) {
    $state.records.status = data.status;
  },
  [types.default.SET_EVENT]($state, data) {
    $state.records.currentEvent = data;
  },
  [types.default.EDIT_EVENT]($state, data) {
    if ($state.records.events.length) {
      let event = $state.records.events.find(event => event.id == data.id);
      if (event)
        // for multi statues
        event.enabled = data.enabled;
    }
  },
  [types.default.SET_SALLA_ORDERS]($state, data) {
    $state.records.ordersWithPagination.orders = data.payload;
    $state.records.ordersWithPagination.meta = data.meta;
  },
  [types.default.SET_SALLA_PRODUCTS]($state, data) {
    $state.records.products.records = data.payload;
    $state.records.products.meta = data.meta;
  },
  [types.default.SET_SALLA_ORDER_DETAILS]($state, data) {
    $state.records.orderDetails = data;
  },
  [types.default.SET_SALLA_ORDER_STATUSES]($state, data) {
    $state.records.orderStatuses = data.payload;
  },
  [types.default.SET_SALLA_UPDATE_ORDER_STATUSES]($state, data) {
    $state.records.orderDetails.status = data.name;
  },
  [types.default.SET_SALLA_CUSTOMERS]($state, data) {
    $state.records.customersWithPagination.customers = [
      ...$state.records.customersWithPagination.customers,
      ...data.payload,
    ];
    $state.records.customersWithPagination.meta = data.meta;
  },
  [types.default.CLEAR_SALLA_CUSTOMERS]($state) {
    $state.records.customersWithPagination.customers = [];
  },
  [types.default.SET_SALLA_LINKED_CUSTOMERS]($state, data) {
    $state.records.linkedCustomer = data;
  },
  [types.default.EDIT_SALLA_CUSTOMER]($state, data) {
    if ($state.records.customersWithPagination.customers.length) {
      let customer = $state.records.customersWithPagination.customers.find(
        customer => customer.id == data.id
      );
      if (customer) customer = data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
